<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <span class="text-h5">Detalhe do perfil</span>
      </v-col>
    </v-row>

    <ButtonBar>
      <v-btn type="button" color="primary" outlined @click="goBack"
        >Voltar</v-btn
      >
      <v-btn
        type="button"
        color="error"
        @click="remove"
        v-if="!isNew"
        v-auth="'PROFILE.REMOVE'"
        :loading="removeButton.loading"
        >Excluir</v-btn
      >
      <v-btn
        type="button"
        color="primary"
        @click="update"
        v-if="!isNew"
        v-auth="'PROFILE.UPDATE'"
        :loading="updateButton.loading"
        >Alterar</v-btn
      >
      <v-btn
        type="button"
        color="primary"
        @click="add"
        v-if="isNew"
        v-auth="'PROFILE.ADD'"
        :loading="addButton.loading"
        >Adicionar</v-btn
      >
    </ButtonBar>

    <v-form ref="form" v-model="validForm">
      <v-row>
        <v-col cols="12" md="7">
          <v-card>
            <v-card-title class="card-title">Dados básicos</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Nome"
                    maxlength="30"
                    v-model="profile.name"
                    required
                    :rules="[$rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :items="data.modules"
                    label="Módulo"
                    v-model="profile.module_id"
                    required
                    :disabled="!isNew"
                    :rules="[$rules.required]"
                    item-value="id"
                    item-text="name"
                    @change="moduleSelected"
                  ></v-select>
                </v-col> </v-row
              ><v-row>
                <v-col>
                  <v-text-field
                    label="Descrição"
                    maxlength="50"
                    v-model="profile.description"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="5">
          <v-card>
            <v-card-title class="card-title">Permissões</v-card-title>
            <v-card-text>
              <v-row>
                <v-col :cols="12">
                  <v-list>
                    <v-list-group
                      v-for="permissionGroup in data.permissionGroups"
                      :key="permissionGroup.name"
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="permissionGroup.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <v-list-item
                        v-for="permission in permissionGroup.permissions"
                        :key="permission.id"
                      >
                        <v-checkbox
                          hide-details
                          multiple
                          v-model="data.selectedPermissions"
                          :value="permission.id"
                          :label="permission.description"
                        />
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import profileApi from "@/common/api/profile.api";
import moduleData from "@/common/data/module.data";
import ButtonBar from "@/components/layout/ButtonBar";
import toaster from "@/common/util/toaster";

export default {
  name: "ProfileDetails",

  components: { ButtonBar },

  data() {
    return {
      data: {
        modules: moduleData,
        selectedPermissions: [],
        permissionGroups: [],
      },
      validForm: true,
      isNew: true,
      profile: {
        module_id: "BACKOFFICE",
        status: "A",
      },
      removeButton: {
        loading: false,
      },
      updateButton: {
        loading: false,
      },
      addButton: {
        loading: false,
      },
    };
  },

  created() {
    this.load(this.$route.params.id);
  },

  methods: {
    moduleSelected(module_id) {
      this.listPermissions(module_id);
    },

    listPermissions(module_id) {
      let filter = {
        module_id: module_id,
      };
      profileApi.listPermissions(filter).then((result) => {
        this.data.permissionGroups = result.data;
      });
    },

    load(profileId) {
      if (profileId !== "novo") {
        this.isNew = false;

        profileApi.get(profileId).then((result) => {
          this.profile = result.data;

          this.moduleSelected(this.profile.module_id);

          this.data.selectedPermissions = this.profile.permissions.map(
            (item) => item.id
          );
        });
      } else {
        this.moduleSelected(this.profile.module_id);
      }
    },

    goBack() {
      this.$router.replace("/perfis");
    },

    async add() {
      this.$refs.form.validate();

      if (await this.$root.$confirm(this.validForm, "profile.add")) {
        let data = {
          name: this.profile.name,
          description: this.profile.description,
          permissions: this.data.selectedPermissions,
          module_id: this.profile.module_id,
          status: this.profile.status,
        };

        this.addButton.loading = true;

        profileApi
          .add(data)
          .then((_) => {
            this.addButton.loading = false;

            toaster.show("Perfil adicionado com sucesso!");
            this.goBack();
          })
          .catch(() => (this.addButton.loading = false));
      }
    },

    async update() {
      this.$refs.form.validate();

      if (await this.$root.$confirm(this.validForm, "profile.update")) {
        let data = {
          name: this.profile.name,
          description: this.profile.description,
          permissions: this.data.selectedPermissions,
          module_id: this.profile.module_id,
          status: this.profile.status,
        };

        this.updateButton.loading = true;

        profileApi
          .update(this.profile.id, data)
          .then((_) => {
            this.updateButton.loading = false;

            toaster.show("Perfil alterado com sucesso!");
            this.goBack();
          })
          .catch(() => (this.updateButton.loading = false));
      }
    },

    async remove() {
      if (await this.$root.$confirm(true, "profile.remove")) {
        this.removeButton.loading = true;

        profileApi
          .remove(this.profile.id)
          .then((_) => {
            this.removeButton.loading = false;

            toaster.show("Perfil excluído com sucesso!");
            this.goBack();
          })
          .catch(() => (this.removeButton.loading = false));
      }
    },
  },
};
</script>